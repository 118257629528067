import { isList, isListItem, MetaType } from 'law-document';
import { Descendant, Editor, Text } from 'slate';

/**
 * 
 * @param editor Slate editor instance
 * @param paragraphId id generated by getParagraphId (e.g. 'chapter-1.art-2.subart-1')
 * @returns list item node or null if not found
 */
export const getNodeByParagraphId = (editor: Editor, paragraphId: string) => {
    return paragraphId.split('.').reduce<Editor | Descendant | null>((node, part) => {
        const [type, nr] = part.split('-');

        if (Text.isText(node)) {
            return null;
        }

        return findListItemNode(node?.children, type as MetaType, nr);
    }, editor);
};

const findListItemNode = (nodes: Descendant[] | undefined, type: MetaType, nr: string) => {
    if (!nodes) {
        return null;
    }

    for (const node of nodes) {
        if (isList(node) && node.meta?.type === type) {
            for (const child of node.children) {
                if (isListItem(child) && child.meta?.nr === nr) {
                    return child;
                }
            }
        }
    }

    return null;
};